import axios from 'axios'
import { store } from '../store/store'

export type MyMethod = 'post' | 'put'

const api = axios.create({
	withCredentials: true,
	baseURL: '/api/v1',
})

api.interceptors.request.use(config => {
	if (config.headers) {
		config.headers.Authorization = `Bearer ${store.getState().user.token}`
		config.headers['Access-Control-Allow-Origin'] = '*'
	}
	return config
})

api.interceptors.response.use(
	config => {
		return config
	},
	async error => {
		// if (error.response.config.url !== '/auth/refresh' && error.response.status === 401) {
		// 	store.dispatch.user.refresh()
		// } else {
		// 	store.dispatch.user.clearUser()
		// 	throw error
		// }

		const originalRequest = error.config
		console.log(error.response)
		if (error.response.status === 401 && !error.config._retry && error.config.url !== '/auth/refresh') {
			originalRequest._retry = true
			await store.dispatch.user.refresh()
			originalRequest.headers.Authorization = `Bearer ${store.getState().user.token}`
			return api(originalRequest)
		} else {
			store.dispatch.user.clearUser()
		}
		return Promise.reject(error)
	}
)

export default api
