import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

//* common for services
import Auth from '../Root/pages/Auth/Auth'
import RequireAuth from './RequireAuth/RequireAuth'

import { Loader } from './UI/Loader/Loader'
const PageNotFound = lazy(() => import('../Error/PageNotFound'))
const Main = lazy(() => import('../Root/components/Layout/Main'))
// const Home = lazy(() => import('../Root/pages/Home/Home'))
const Profile = lazy(() => import('../Root/pages/Profile/Profile'))
// const Services = lazy(() => import('../Root/pages/Services/Services'))
const Users = lazy(() => import('../Root/pages/Users/Users'))

//* Moment service
const MomentApp = lazy(() => import('../Moment/App'))

const MomentHome = lazy(() => import('../Moment/pages/Home/Home'))
const MomentMain = lazy(() => import('../Moment/components/layout/Main'))
const MomentFlange = lazy(() => import('../Moment/pages/Flange/Flange'))
const MomentFlangeForm = lazy(() => import('../Moment/pages/Flange/Form'))
const MomentFlangeResult = lazy(() => import('../Moment/pages/Flange/Result'))
const MomentCap = lazy(() => import('../Moment/pages/Cap/Cap'))
const MomentCapForm = lazy(() => import('../Moment/pages/Cap/Form'))
const MomentCapResult = lazy(() => import('../Moment/pages/Cap/Result'))
const MomentFloatingHead = lazy(() => import('../Moment/pages/FloatingHead/FloatingHead'))
const MomentFloatingHeadFrom = lazy(() => import('../Moment/pages/FloatingHead/Form'))
const MomentFloatingHeadResult = lazy(() => import('../Moment/pages/FloatingHead/Result'))
const MomentDevCooling = lazy(() => import('../Moment/pages/DevCooling/DevCooling'))
const MomentDevCoolingForm = lazy(() => import('../Moment/pages/DevCooling/Form'))
const MomentDevCoolingResult = lazy(() => import('../Moment/pages/DevCooling/Result'))
const MomentGasCooling = lazy(() => import('../Moment/pages/GasCooling/GasCooling'))
const MomentGasCoolingForm = lazy(() => import('../Moment/pages/GasCooling/Form'))
const MomentGasCoolingResult = lazy(() => import('../Moment/pages/GasCooling/Result'))
const MomentExCircle = lazy(() => import('../Moment/pages/ExCircle/ExCircle'))
const MomentExCircleForm = lazy(() => import('../Moment/pages/ExCircle/Form'))
const MomentExCircleResult = lazy(() => import('../Moment/pages/ExCircle/Result'))
const MomentExRect = lazy(() => import('../Moment/pages/ExRect/ExRect'))
const MomentExRectForm = lazy(() => import('../Moment/pages/ExRect/Form'))
const MomentExRectResult = lazy(() => import('../Moment/pages/ExRect/Result'))

//* Moment service Admin
const MomentAdmin = lazy(() => import('../Moment/pages/Admin/Admin'))
const MomentAdminMaterials = lazy(() => import('../Moment/pages/Admin/Materials/Materials'))
const MomentAdminGaskets = lazy(() => import('../Moment/pages/Admin/Gaskets/Gaskets'))
const MomentAdminStandarts = lazy(() => import('../Moment/pages/Admin/Standarts/Standarts'))
const MomentAdminBolts = lazy(() => import('../Moment/pages/Admin/Bolts/Bolts'))
const MomentAdminAvo = lazy(() => import('../Moment/pages/Admin/Avo/Avo'))
const MomentAdminDevice = lazy(() => import('../Moment/pages/Admin/Avo/Device/Device'))
const MomentAdminPressure = lazy(() => import('../Moment/pages/Admin/Avo/Pressure/Pressure'))
const MomentAdminTubeCount = lazy(() => import('../Moment/pages/Admin/Avo/TubeCount/TubeCount'))
const MomentAdminFinning = lazy(() => import('../Moment/pages/Admin/Avo/FinningFactor/FinningFactor'))
const MomentAdminSection = lazy(() => import('../Moment/pages/Admin/Avo/SectionExecution/SectionExecution'))
const MomentAdminNumberOfMoves = lazy(() => import('../Moment/pages/Admin/Avo/NumberOfMoves/NumberOfMoves'))
const MomentAdminTubeLength = lazy(() => import('../Moment/pages/Admin/Avo/TubeLength/TubeLength'))
const MomentAdminNameGasket = lazy(() => import('../Moment/pages/Admin/Avo/NameGasket/NameGasket'))

//* Urls
export const ProfileUrl = '/profile'

export const MomentUrl = ''

export const MyRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path='/auth' element={<Auth />} />
				<Route
					path='/'
					element={
						<RequireAuth>
							<Main />
						</RequireAuth>
					}
				>
					<Route
						path='/'
						element={
							<RequireAuth>
								<MomentApp />
							</RequireAuth>
						}
					>
						<Route index element={<MomentHome />} />
						<Route path='' element={<MomentMain />}>
							<Route path='flange' element={<MomentFlange />}>
								<Route index element={<MomentFlangeForm />} />
								<Route path='result' element={<MomentFlangeResult />} />
							</Route>
							<Route path='cap' element={<MomentCap />}>
								<Route index element={<MomentCapForm />} />
								<Route path='result' element={<MomentCapResult />} />
							</Route>
							<Route path='floating-head' element={<MomentFloatingHead />}>
								<Route index element={<MomentFloatingHeadFrom />} />
								<Route path='result' element={<MomentFloatingHeadResult />} />
							</Route>
							<Route path='dev-cooling' element={<MomentDevCooling />}>
								<Route index element={<MomentDevCoolingForm />} />
								<Route path='result' element={<MomentDevCoolingResult />} />
							</Route>
							<Route path='gas-cooling' element={<MomentGasCooling />}>
								<Route index element={<MomentGasCoolingForm />} />
								<Route path='result' element={<MomentGasCoolingResult />} />
							</Route>
							<Route path='express-circle' element={<MomentExCircle />}>
								<Route index element={<MomentExCircleForm />} />
								<Route path='result' element={<MomentExCircleResult />} />
							</Route>
							<Route path='express-rectangle' element={<MomentExRect />}>
								<Route index element={<MomentExRectForm />} />
								<Route path='result' element={<MomentExRectResult />} />
							</Route>
						</Route>

						<Route path='admin' element={<MomentAdmin />}>
							<Route path='edit/materials' element={<MomentAdminMaterials />} />
							<Route path='edit/gaskets' element={<MomentAdminGaskets />} />
							<Route path='edit/standarts' element={<MomentAdminStandarts />} />
							<Route path='edit/bolts' element={<MomentAdminBolts />} />
							<Route path='edit/avo' element={<MomentAdminAvo />}>
								<Route path='device-mod' element={<MomentAdminDevice />} />
								<Route path='pressure' element={<MomentAdminPressure />} />
								<Route path='tube-count' element={<MomentAdminTubeCount />} />
								<Route path='finning-factor' element={<MomentAdminFinning />} />
								<Route path='section-execution' element={<MomentAdminSection />} />
								<Route path='number-of-moves' element={<MomentAdminNumberOfMoves />} />
								<Route path='tube-length' element={<MomentAdminTubeLength />} />
								<Route path='name-gasket' element={<MomentAdminNameGasket />} />
							</Route>
						</Route>
					</Route>

					{/* <Route index element={<Home />} /> */}
					{/* <Route path='services' element={<Services />} /> */}
					<Route path='profile' element={<Profile />} />
					<Route path='users/:page' element={<Users />} />
				</Route>

				{/* moment */}

				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</Suspense>
	)
}
